import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../layouts/Layout'
import BasicHero from '../components/Hero/BasicHero'
import { Flex } from 'theme-ui'

const Leader = ({ pageContext }) => {
  console.log(pageContext)
  return (
    <Layout theme="dark">
      <BasicHero
        heading={pageContext.name}
        titleSubHeading={pageContext.title}
      />
      <section
        sx={{
          display: 'flex',
          justifyContent: 'center',
          justifySelf: 'center',
          flexDirection: ['column-reverse', null, null, 'row'],
          py: [5, null, null, 6],
          maxWidth: 1500,
          px: [4, null, null, 0],
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            minHeight: ['100%', null, null, 500],
            justifyContent: 'space-between',
            width: ['100%', null, null, '50%'],
            pr: [0, null, null, 6],
          }}
        >
          <p
            sx={{
              fontSize: [16],
              color: '#3e3f4e',
              fontWeight: 300,
              m: 0,
              lineHeight: 1.6,
            }}
          >
            {pageContext.bio}
          </p>
          <Link
            to="/leadership"
            sx={{
              variant: 'buttons.primary',
              mt: [5, null, null, 0],
              alignSelf: ['center', null, null, 'flex-start'],
            }}
          >
            Return to Leadership Page
          </Link>
        </Flex>
        <img
          src={pageContext.profileImage}
          sx={{
            borderRadius: 4,
            mb: [4, null, null, 0],
            width: ['90vw', null, null, 360],
            height: ['auto', null, null, 360],
            boxShadow: '0 20px 30px rgba(20,20,36,.1)',
          }}
        />
      </section>
    </Layout>
  )
}

export default Leader

// export const Head = ({ pageContext }) => {
//   return <Seo title={pageContext?.node.name} />
// }
// export const query = graphql`
//   query GetMember($slug: String!) {
//     allLeadershipJson(slug: { eq: $slug }) {
//       edges {
//         node {
//           bio
//           slug
//           name
//           title
//         }
//       }
//     }
//   }
// `
